<template>
    <div>
        <p>En train de traiter le token...</p>
    </div>
</template>

<script>
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
    name: "token_callback",
    mounted() {
        const store = useStore();
        const router = useRouter();

        // Récupérez le token de l'URL
        const token = this.$route.query.token;
        //console.log(token);

        JwtService.saveToken(token);
        setTimeout(() => {
            // Send login request
            store
                .dispatch(Actions.LOGIN_SSO_SAML, JwtService.getToken())
                .then(() => {
                    router.push({ name: "dashboard" });
                })
                .catch((error) => {
                    let errorMsg = "Une erreur est survenue. Veuillez réessayer dans un insta";
                    // if (store.getters.getErrors.global) {
                    //     errorMsg = store.getters.getErrors.global;
                    // }
                    console.log(error)
                    Swal.fire({
                        text: errorMsg,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Fermer",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                    });
                });
        }, 500);
    },
    // async mounted() {
    //     const store = useStore();
    //     const router = useRouter();

    //     // Récupérez le token de l'URL
    //     const token = this.$route.query.token;
    //     console.log(token);

    //     if (token) {
    //       await JwtService.saveToken(token); // Assuming JwtService.saveToken returns a Promise
    //       // After saving the token successfully
    //       router.push({ name: "dashboard" });
    //     } else {
    //       // Handle the case where the token is not available
    //       console.error('Token not found');
    //     }
    // },
};
</script>
